import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, MenuItem, Autocomplete } from '@mui/material';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import Tooltip from '@mui/material/Tooltip';
import IconUI from '../components/iu/icon_ui';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { FaUserPen } from 'react-icons/fa6';
import dayjs from 'dayjs';
import { FaHandsHelping } from 'react-icons/fa';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import { maxLengthLimitInput, onlyNumber } from '../utils/inputsRules';
import { GlobalContext } from '../context/global_context';
import { useNavigate } from 'react-router-dom';

const SolicitudesUser = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        // border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };

    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        // border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 600,
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const goTo = useNavigate();
    const { setPeticion } = useContext(GlobalContext)

    const [state, setState] = useState({
        citizens: [],
        requests: [],
        ciudadano: [],
        tiposPeticion: [],
        dependencias: [],
        municipios: [],
        localidades: [],
        citizenSelected: '',
        dateSelected: '',
        fontSelected: '',
        numTour: '',
        nameTour: '',
        observations: '',
        fonts: [],
        openEditar: false,
        openEditarCiudadano: false,
        openEliminar: false,
        openPeticion: false,
        citizenEditSelected: {},
    });

    const registro = localStorage.getItem('REGISTRO');
    const eliminar = localStorage.getItem('BORRAR_ASUNTO');

    const [titulosState, setTitulos] = useState({
        data: []
    })
    const [cargosState, setCargos] = useState({
        data: []
    })

    const [formularioState, setFormulario] = useState({
        ciudadano_id: '',
        observaciones: '',
        fuente_solicitud_id: '',
        fecha_audiencia: '',
        numero_gira: '',
        lugar_gira: ''
    });

    const [formularioPeticionState, setFormularioPeticion] = useState({
        tipo_peticion_id: '',
        dependencia_id: '',
        atencion_inmediata: true,
        peticion: '',
    });

    const [loader, setLoader] = useState({
        loading: false
    });

    const [openModal, setopenModal] = useState(false)

    const { showNotification } = useContext(NotificacionContext)


    const [errors, setErrors] = useState({});

    const openModalPeticion = async(data) => {
      setLoader({ ...loader, loading: true })
      const tipoPeticiones = await services({ method: "GET", service: "catTipoPeticiones/listarPeticiones", body: null });
      const dependecias = await services({ method: "GET", service: "dependencias/listar", body: null });
      if(tipoPeticiones.status === 200 && dependecias.status === 200){
        setState({ ...state, openPeticion: true, citizenEditSelected: data, tiposPeticion: tipoPeticiones.data.data, dependencias: dependecias.data.data });
        setLoader({ ...loader, loading: false })
      }
    }

    const openmodal = async(data) => {
      const fonts = await services({ method: "GET", service: "catFuentesSolicitudes/listar", body: null });
      if(fonts.status === 200){
        setState({ ...state, openEditar: true, citizenEditSelected: data, fonts: fonts.data.data });
      }
    }

    const openModalEditarCiudadano = async (data) => {
      setLoader({ ...loader, loading: true })
      const titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null });
      const cargos = await services({ method: 'GET', service: `catTipoCargos/listarCargos`, body: null });
      const municipios = await services({ method: 'GET', service: `catMunicipios/listar`, body: null })
      let ciudadano = await services({ method: 'POST', service: `ciudadanos/mostrar`, body: { id: data.ciudadano_id } });
        if (ciudadano.status === 200 && titulos.status === 200 && cargos.status === 200) {
          setLoader({ ...loader, loading: false })
          setTitulos({ ...titulosState, data: titulos.data.data });
          setCargos({ ...cargosState, data: cargos.data.data });
          setState({ ...state, ciudadano: ciudadano.data.data, openEditarCiudadano: true, municipios: municipios.data.data });
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'ups... Algo sucedió', 'Error al obtener información', null, 4);
            handleCloseEditarCiudadano()
        }
    }

    const openModalAñadir = async()=>{
      setLoader({ ...loader, loading: true })
      const citizens = await services({ method: "GET", service: "ciudadanos/listar", body: null });
      const fonts = await services({ method: "GET", service: "catFuentesSolicitudes/listar", body: null });
      
      if(citizens.status === 200 && fonts.status === 200){
        setState({ ...state, citizens: citizens.data.data, fonts: fonts.data.data})
        setLoader({ ...loader, loading: false })
        setopenModal(true)
      }else {
        setLoader({ ...loader, loading: false })
        showNotification('error', 'Ups...Algo sucedió', 'Al cargar las solicitudes', null, 4);
      }
    }

    const openModalEliminar = (data) => {
        setState({ ...state, openEliminar: true, citizenEditSelected: data });
    }

    const columns = [

        {
            name: "folio",
            label: "FOLIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let folio = state.requests[dataIndex].folio;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{folio}</div>)
                }
            }
        },
        {
            name: "nombre",
            label: "CIUDADANO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.requests[dataIndex].nombre;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "fecha_creacion",
            label: "FECHA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let fecha = moment(state.requests[dataIndex].fecha_creacion,'DD-MM-YYYY');
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{fecha.format('DD-MM-YYYY')}</div>)
                }
            }
        },
        {
            name: "estatus_solicitud",
            label: "ESTADO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let estado = state.requests[dataIndex].estatus_solicitud;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{estado}</div>)
                }
            }
        },
        {
            name: "fuente_solicitud",
            label: "FUENTE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let fuente_solicitud = state.requests[dataIndex].fuente_solicitud;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{fuente_solicitud}</div>)
                }
            }
        },
        {
            name: "numero_gira",
            label: "No. GIRA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let gira = state.requests[dataIndex].numero_gira;
                    if (gira !== null) {
                        return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{gira}</div>)
                    } else {
                        return (<>-</>)
                    }
                }
            }
        },
        {
            name: "lugar_gira",
            label: "LUGAR GIRA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let lugar = state.requests[dataIndex].lugar_gira;
                    if (lugar !== null) {

                        return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{lugar}</div>)
                    } else {
                        return (<>-</>)
                    }
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                display: registro || eliminar ? true : false,
                customBodyRenderLite: (dataIndex, i) => (
                    registro && eliminar ?
                        <>
                            <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center" >
                                <Tooltip title="EDITAR CIUDADANO">
                                    <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                        onClick={
                                            () => {
                                                openModalEditarCiudadano(state.requests[dataIndex])
                                            }}
                                    >
                                        <IconUI size={'20px'} color={'black'}><FaUserPen /></IconUI>

                                    </div>
                                </Tooltip>
                                <Tooltip title="CREAR PETICIÓN">
                                    <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                        onClick={
                                            () => {
                                                openModalPeticion(state.requests[dataIndex])
                                            }}
                                    >
                                        <IconUI size={'20px'} color={'black'}><FaHandsHelping /></IconUI>

                                    </div>
                                </Tooltip>
                                <Tooltip title="EDITAR SOLICITUD">
                                    <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                        onClick={
                                            () => {
                                                openmodal(state.requests[dataIndex])
                                            }}
                                    >
                                        <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                    </div>
                                </Tooltip>
                                <Tooltip title="ELIMINAR SOLICITUD">
                                    <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--warning cta--icon icon_btn'
                                        onClick={
                                            () => {
                                                openModalEliminar(state.requests[dataIndex])
                                            }
                                        }>
                                        <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>
                                    </div>
                                </Tooltip>


                            </div>
                        </>
                        :
                        registro ?
                            <>
                                <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center" >
                                    <Tooltip title="EDITAR CIUDADANO">
                                        <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                            onClick={
                                                () => {
                                                    openModalEditarCiudadano(state.requests[dataIndex])
                                                }}
                                        >
                                            <IconUI size={'20px'} color={'black'}><FaUserPen /></IconUI>

                                        </div>
                                    </Tooltip>
                                    <Tooltip title="CREAR PETICIÓN">
                                        <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                            onClick={
                                                () => {
                                                    openModalPeticion(state.requests[dataIndex])
                                                }}
                                        >
                                            <IconUI size={'20px'} color={'black'}><FaHandsHelping /></IconUI>

                                        </div>
                                    </Tooltip>
                                    <Tooltip title="EDITAR SOLICITUD">
                                        <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                            onClick={
                                                () => {
                                                    openmodal(state.requests[dataIndex])
                                                }}
                                        >
                                            <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                        </div>
                                    </Tooltip>
                                </div>
                            </>
                            :
                            eliminar ?
                                <>
                                    <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center" >
                                        <Tooltip title="ELIMINAR SOLICITUD">
                                            <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--warning cta--icon icon_btn'
                                                onClick={
                                                    () => {
                                                        openModalEliminar(state.requests[dataIndex])
                                                    }
                                                }>
                                                <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </>
                                :
                                <></>
                )
            }
        },


    ];

    const validarFormulario = () => {
        let newErrors = {};
        if (!formularioState.ciudadano_id) { newErrors.citizen = "Este Campo es requerido" }
        if (!formularioState.fuente_solicitud_id) { newErrors.font = "Este Campo es requerido" }
        // if (!state.dateSelected) { newErrors.date = "Este Campo es requerido" }
        // if (!state.observations) { newErrors.observations = "Este Campo es requerido" }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;

    }

    const validarEditar = () => {
        let newErrors = {};
        if (!state.citizenEditSelected.fuente_solicitud_id) { newErrors.font = "Este Campo es requerido" }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    // validaciones del editar
    const validarEditarCiudadano = () => {
        let newErrors = {};
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!state.ciudadano.nombre) { newErrors.nombre = 'Este Campo es Requerido' }
        if (!state.ciudadano.apellido_paterno) { newErrors.apellidoPaterno = 'Este Campo es Requerido' }
        if (state.ciudadano.tipo_persona === "") { newErrors.tipo = 'Este Campo es Requerido' }
        if (state.ciudadano.ine !== null && state.ciudadano.ine !== "" && state.ciudadano.ine.length < 13) { newErrors.ine = 'Favor poner un número de identificación válido' }
        if (state.ciudadano.telefono !== null && state.ciudadano.telefono.length < 10) { newErrors.telefono = 'Favor poner un número de 10 dígitos' }
        if (state.ciudadano.cp !== null && state.ciudadano.cp.length < 5) { newErrors.cp = 'Favor poner un código postal válido' }

        if (state.ciudadano.email !== null && state.ciudadano.email !== "") {
            let valido = state.ciudadano.email.match(regexEmail)
            if (!valido) {
                newErrors.email = 'Ingrese un email válido'
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const validarFormularioPeticion = () => {
        let newErrors = {};
        if (!formularioPeticionState.tipo_peticion_id) { newErrors.tipo = "Este Campo es requerido" }
        if (!formularioPeticionState.dependencia_id) { newErrors.dependencia = "Este Campo es requerido" }
        // if (formularioPeticionState.atencion_inmediata) { newErrors.atencion = "Este Campo es requerido" }
        if (!formularioPeticionState.peticion) { newErrors.peticion = "Este Campo es requerido" }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const limpiarFormularioPeticion = () => {

        setFormularioPeticion({
            ...formularioPeticionState,
            tipo_peticion_id: '',
            dependencia_id: '',
            atencion_inmediata: true,
            peticion: '',
        })

    }

    const limpiarFormulario = () => {
        setFormulario({
            ...formularioState,
            ciudadano_id: '',
            observaciones: '',
            fuente_solicitud_id: '',
            fecha_audiencia: '',
            numero_gira: '',
            lugar_gira: ''
        })
    }

    const createRequest = async () => {
        if (validarFormulario()) {
            setLoader({ ...loader, loading: true })
            const body = {
                ciudadano_id: formularioState.ciudadano_id,
                fuente_solicitud_id: formularioState.fuente_solicitud_id,
                fecha_audiencia: formularioState.fecha_audiencia,
                numero_gira: formularioState.numero_gira,
                lugar_gira: formularioState.lugar_gira,
                observaciones: formularioState.observaciones
            }
            const request = await services({ method: "POST", service: "solicitudes/crear", body })
            if (request.status === 200) {
                const solicitudes = await services({ method: "GET", service: "solicitudes/listarSolicitudes", body: null })
                if (solicitudes.status === 200) {
                    setState({ ...state, requests: solicitudes.data.data })
                    limpiarFormulario();
                    setopenModal(false);
                    setLoader({ ...loader, loading: false, })
                    showNotification('success','Solicitud Creada con Éxito', 'Tu folio es:'+ request.data.folio, null);
                } else {
                    setState({ ...state, citizenSelected: '', dateSelected: '', fontSelected: '', numTour: '', nameTour: '', observations: '' });
                    setopenModal(false)
                    limpiarFormulario();
                    setLoader({ ...loader, loading: false, })
                    showNotification('error', 'Crear Solicitud', 'Algo ocurrió inténtelo mas tarde', null, 4);
                }
            } else {
                setState({ ...state, citizenSelected: '', dateSelected: '', fontSelected: '', numTour: '', nameTour: '', observations: '' });
                setopenModal(false)
                limpiarFormulario();
                setLoader({ ...loader, loading: false, })
                showNotification('error', 'Crear Solicitud', request.data.message, null, 4);
            }

        } else {
            setLoader({ ...loader, loading: false, })
            showNotification('error', 'Ups...', 'Favor de llenar los campos', null, 4);
        }

    }

    const editRequest = async () => {
        if (validarEditar) {
            setLoader({ ...loader, loading: true })
            const editarSolicitud = await services({
                method: 'POST', service: `solicitudes/editarSolicitud`, body: {
                    id: state.citizenEditSelected.id,
                    ciudadano_id: state.citizenEditSelected.ciudadano_id,
                    fuente_solicitud_id: state.citizenEditSelected.fuente_solicitud_id,
                    fecha_audiencia: state.citizenEditSelected.fecha_audiencia,
                    estatus_solicitud_id: state.citizenEditSelected.estatus_solicitud_id,
                    numero_gira: state.citizenEditSelected.numero_gira,
                    lugar_gira: state.citizenEditSelected.lugar_gira?.toUpperCase(),
                    observaciones: state.citizenEditSelected.observaciones?.toUpperCase()
                }
            })
            if (editarSolicitud.status === 200) {
                const request = await services({ method: "GET", service: "solicitudes/listarSolicitudes", body: null })
                if (request.status === 200) {
                    setLoader({ ...loader, loading: false, })
                    setState({ ...state, requests: request.data.data, openEditar: false })
                    showNotification('success', 'Solicitud', 'Se ha editado con Éxito', null, 4);
                } else {
                    setLoader({ ...loader, loading: false, })
                    setState({ ...state, openEditar: false })
                    showNotification('error', 'Ups...', 'Error al editar la solicitud', null, 4);
                }
            } else {
                setLoader({ ...loader, loading: false, })
                setState({ ...state, openEditar: false })
                showNotification('error', 'Ups...', 'Error al editar la solicitud', null, 4);
            }

        } else {
            showNotification('error', 'Ups...', 'Error favor de llenar los campos', null, 4);
        }
    }

    const deleteRequest = async () => {
        setLoader({ ...state, loading: true })
        const eliminarSolicitud = await services({ method: 'POST', service: `solicitudes/eliminarSolicitud`, body: { id: state.citizenEditSelected.id } })
        if (eliminarSolicitud.status === 200) {
            let solicitudes = await services({ method: "GET", service: "solicitudes/listarSolicitudes", body: null })
            if (solicitudes.status === 200) {
                setState({ ...state, requests: solicitudes.data.data, openEliminar: false })
                showNotification('success', 'Solicitud Eliminada con Éxito', '', null, 4);
                setLoader({ ...state, loading: false })
            } else {
                setLoader({ ...state, loading: false })
                setState({ ...state, openEliminar: false })
                showNotification('error', 'Ups...', 'Error al Eliminar el Solicitud', null, 4);
            }
        } else if (eliminarSolicitud.status === 409) {
            setLoader({ ...state, loading: false })
            setState({ ...state, openEliminar: false })
            showNotification('error', 'Ups...', 'Error la solicitud seleccionada aun tiene peticiones asignadas', null, 4);
        } else {
            setLoader({ ...state, loading: false })
            setState({ ...state, openEliminar: false })
            showNotification('error', 'Ups...', 'Error al Eliminar el Solicitud', null, 4);
        }
    }

    const crearPeticion = async () => {
        setLoader({ ...loader, loading: true })
        if (validarFormularioPeticion()) {
            const peticion = await services({
                method: 'POST', service: 'peticiones/crear', body: {
                    solicitud_id: state.citizenEditSelected.id,
                    tipo_peticion_id: formularioPeticionState.tipo_peticion_id,
                    dependencia_id: formularioPeticionState.dependencia_id,
                    atencion_inmediata: formularioPeticionState.atencion_inmediata,
                    peticion: formularioPeticionState.peticion,
                }
            })
            if (peticion.status === 200) {
                const solicitudes = await services({ method: "GET", service: "solicitudes/listarSolicitudes", body: null })
                if (solicitudes.status === 200) {
                    showNotification('success', 'Petición', 'Creada con Éxito', null, 4);
                    limpiarFormularioPeticion()
                    setState({ ...state, openPeticion: false, requests: solicitudes.data.data });
                    setLoader({ ...loader, loading: false })
                    setPeticion(peticion.data.folio);
                    goTo(`/peticionesCiudadanoUsuario/${state.citizenEditSelected.ciudadano_id}`);
                } else {
                    setState({ ...state, openPeticion: false });
                    setLoader({ ...loader, loading: false })
                    limpiarFormularioPeticion()
                    showNotification('error', 'Ups... Algo sucedió', 'Error al Crear Petición', null, 4);
                }
            } else {
                setState({ ...state, openPeticion: false });
                setLoader({ ...loader, loading: false })
                limpiarFormularioPeticion()
                showNotification('error', 'Ups... Algo sucedió', 'Error al Crear Petición', null, 4);
            }

        } else {
            showNotification('error', 'Ups... ', 'Por favor llena los campos marcados', null, 4);
            setLoader({ ...loader, loading: false })
        }
    }

    // editar ciudadano
    const editarCiudadano = async () => {
        setLoader({ ...loader, loading: true })
        if (validarEditarCiudadano()) {
            const ciudadano = await services({
                method: 'POST', service: `ciudadanos/editar`, body: {
                    id: state.ciudadano.id,
                    cat_tipo_titulo_id: state.ciudadano.cat_tipo_titulo_id,
                    nombre: state.ciudadano.nombre?.toUpperCase(),
                    apellido_paterno: state.ciudadano.apellido_paterno?.toUpperCase(),
                    apellido_materno: state.ciudadano.apellido_materno?.toUpperCase(),
                    tipo_persona: state.ciudadano.tipo_persona,
                    edad: state.ciudadano.edad,
                    sexo: state.ciudadano.sexo,
                    ine: state.ciudadano.ine?.toUpperCase(),
                    cat_tipo_cargo_id: state.ciudadano.cat_tipo_cargo_id,
                    telefono: state.ciudadano.telefono,
                    email: state.ciudadano.email?.toUpperCase(),
                    // fecha_nacimiento: state.ciudadano.fecha_nacimiento,
                    calle: state.ciudadano.calle?.toUpperCase(),
                    numero_ext: state.ciudadano.numero_ext?.toUpperCase(),
                    colonia: state.ciudadano.colonia?.toUpperCase(),
                    cp: state.ciudadano.cp,
                    estado: state.ciudadano.estado,
                    municipio_id: state.ciudadano.municipio_id,
                    localidad_id: state.ciudadano.localidad_id,
                }
            })
            if (ciudadano.status === 200) {
                let solicitudes = await services({ method: "GET", service: "solicitudes/listarSolicitudes", body: null })
                if (solicitudes.status === 200) {
                    setState({ ...state, requests: solicitudes.data.data, openEditarCiudadano: false })
                    showNotification('success', 'Ciudadano', 'Editado con Éxito', null, 4);
                    setLoader({ ...loader, loading: false })
                } else {
                    setLoader({ ...loader, loading: false })
                    showNotification('error', 'Ups... Algo Sucedió', 'Error al Cargar las Solicitudes', null, 4);
                    setState({ ...state, openEditarCiudadano: false })
                }
            } else {
                setLoader({ ...loader, loading: false })
                showNotification('error', 'Ups... Algo Sucedió', ciudadano.data.message, null, 4);
                setState({ ...state, openModalEditar: false })
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Verifica', 'Los campos marcados', null, 4);
        }
    }

    const handleEditFont = (e) => {
        setState({ ...state, citizenEditSelected: { ...state.citizenEditSelected, fuente_solicitud_id: e.target.value } })
    }

    const handleEditDate = (e) => {
        const selectedDate = dayjs(e.$d);
        setState({ ...state, citizenEditSelected: { ...state.citizenEditSelected, fecha_audiencia: selectedDate.format('YYYY-MM-DD') } })
    }

    const handleEditObservations = (e) => {
        setState({ ...state, citizenEditSelected: { ...state.citizenEditSelected, observaciones: e.target.value } })
    }

    const handleTipoPeticiones = (e) => {
        setFormularioPeticion((formularioPeticionState) => ({ ...formularioPeticionState, tipo_peticion_id: e ? e.id : null, }));
    }

    const handleDependencias = (e) => {
        setFormularioPeticion((formularioPeticionState) => ({ ...formularioPeticionState, dependencia_id: e ? e.id : null, }));
    }

    const handlePrioridad = (e) => {
        setFormularioPeticion({ ...formularioPeticionState, atencion_inmediata: e.target.value })
    }

    const handleCitizen = (e) => {
        setFormulario({ ...formularioState, ciudadano_id: e.target.value })
    }

    const handleChangeDate = (e) => {
        const selectedDate = dayjs(e.$d);
        setFormulario({ ...formularioState, fecha_audiencia: selectedDate.format('YYYY-MM-DD') })
    }

    const handleChangeFont = (e) => {
        setFormulario({ ...formularioState, fuente_solicitud_id: e.target.value })
    }

    const handleChangeObservations = (e) => {
        setFormulario({ ...formularioState, observaciones: e.target.value })
    }

    const handleClose = () => {
        setState({ ...state, openEditar: false })
    }
    const handleCloseEditarCiudadano = () => {
        setState({ ...state, openEditarCiudadano: false })
    }

    const handleCloseEliminar = () => {
        setState({ ...state, openEliminar: false })
    }

    const handleClosePeticion = () => {
        setState({ ...state, openPeticion: false });
    }



    // manejo de formulario editar usuario
    const handleSeleccionadoTitulo = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, cat_tipo_titulo_id: e.target.value } })
    }
    const handleSeleccionadoNombre = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, nombre: e.target.value } })
    }
    const handleSeleccionadoApellidoPaterno = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, apellido_paterno: e.target.value } })
    }
    const handleSeleccionadoApellidoMaterno = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, apellido_materno: e.target.value } })
    }
    const handleSeleccionadoTipo = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, tipo_persona: e.target.value } })
    }
    const handleSeleccionadoSexo = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, sexo: e.target.value } })
    }
    const handleSeleccionadoIne = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, ine: e.target.value } })
    }
    const handleSeleccionadoCargo = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, cat_tipo_cargo_id: e ? e.id : null } })
    }
    const handleSeleccionadoTelefono = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, telefono: e.target.value } })
    }
    const handleSeleccionadoEmail = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, email: e.target.value } })
    }
    // const handleSeleccionadoFechaNacimiento = (e) => {
    //     if (e === null) {
    //         setState({ ...state, ciudadano: { ...state.ciudadano, fecha_nacimiento: null } })
    //     } else {
    //         const selectedDate = dayjs(e);
    //         setState({ ...state, ciudadano: { ...state.ciudadano, fecha_nacimiento: selectedDate.format('YYYY-MM-DD') } })
    //     }
    // }
    const handleSeleccionadoEdad = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, edad: e.target.value } })
    }
    const handleSeleccionadoCalle = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, calle: e.target.value } })
    }
    const handleSeleccionadoNumero = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, numero_ext: e.target.value } })
    }
    const handleSeleccionadoColonia = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, colonia: e.target.value } })
    }
    const handleSeleccionadoCp = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, cp: e.target.value } })
    }
    const handleSeleccionadoEstado = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, estado: e.target.value } })
    }
    const handleSeleccionadoMunicipio = async (e) => {
        let localidades = await services({ method: 'POST', service: `catLocalidades/listaPorMunicipio`, body: { municipio_id: e.target.value } });
        if (localidades.status === 200) {
            setState({ ...state, localidades: localidades.data.data, ciudadano: { ...state.ciudadano, municipio_id: e.target.value } })
        } else {
            showNotification('error', 'Ups...', localidades.data.message, null, 4)
        }
    }
    const handleSeleccionadoLocalidad = (e) => {
        setState({ ...state, ciudadano: { ...state.ciudadano, localidad_id: e.target.value } })
    }


    const getCitizensAndRequests = async () => {
      setLoader({ ...loader, loading: true })
      const request = await services({ method: "GET", service: "solicitudes/listarSolicitudes", body: null });      
      
      if (request.status === 200  ) {
        setState({ ...state, requests: request.data.data });
        setLoader({ ...loader, loading: false });
      } else {
        setLoader({ ...loader, loading: false })
        showNotification('error', 'Ups...Algo sucedió', 'Al cargar las solicitudes', null, 4);
      }
    }



    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            getCitizensAndRequests();
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }
                {registro ?
                    <div className='container'>
                        <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                            <Grid item >
                                <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { openModalAñadir() }}>
                                    Añadir Solicitud
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </div>
                    : <></>
                }
                <DataTable
                    data={state.requests}
                    title={`TABLA DE SOLICITUDES`}
                    columns={columns}
                />
                {/* Modal para editar usuario */}
                <Modal
                    open={state.openEditarCiudadano}
                    onClose={handleCloseEditarCiudadano}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas'>
                            EDITAR CIUDADANO
                        </h3>
                        <hr />
                        <h5>DATOS GENERALES</h5>
                        <hr />
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="TITULO"
                                    variant="outlined"
                                    select
                                    error={!!errors.titulo}
                                    helperText={errors.titulo || ''}
                                    onChange={handleSeleccionadoTitulo}
                                    value={state.ciudadano.cat_tipo_titulo_id}
                                >
                                    {titulosState.data && titulosState.data.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.tipo_titulo}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    error={!!errors.nombre}
                                    helperText={errors.nombre || ''}
                                    value={state.ciudadano.nombre}
                                    onChange={handleSeleccionadoNombre}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-apellidoP"
                                    label="APELLIDO PATERNO"
                                    variant="outlined"
                                    error={!!errors.apellidoPaterno}
                                    helperText={errors.apellidoPaterno || ''}
                                    value={state.ciudadano.apellido_paterno}
                                    onChange={handleSeleccionadoApellidoPaterno}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-apellidoM"
                                    label="APELLIDO MATERNO"
                                    variant="outlined"
                                    error={!!errors.apellidoMaterno}
                                    helperText={errors.apellidoMaterno || ''}
                                    value={state.ciudadano.apellido_materno}
                                    onChange={handleSeleccionadoApellidoMaterno}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-TipoUsuario"
                                    label="TIPO"
                                    variant="outlined"
                                    select
                                    error={!!errors.tipo}
                                    helperText={errors.tipo || ''}
                                    onChange={handleSeleccionadoTipo}
                                    value={state.ciudadano.tipo_persona}
                                >
                                    <MenuItem value={1}>CIUDADANO</MenuItem>
                                    <MenuItem value={0}>ASOCIACIÓN CIVIL</MenuItem>

                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-INE"
                                    label="EDAD"
                                    variant="outlined"
                                    error={!!errors.edad}
                                    helperText={errors.edad || ''}
                                    onChange={handleSeleccionadoEdad}
                                    value={state.ciudadano.edad}
                                    inputProps={{ maxLength: 2 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="FECHA DE NACIMIENTO"
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width: 1 }}
                                        value={state.ciudadano.fecha_nacimiento ? dayjs(state.ciudadano.fecha_nacimiento) : null}
                                        format="DD/MM/YYYY"
                                        views={['year', 'month', 'day']}
                                        onChange={handleSeleccionadoFechaNacimiento}
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-TipoUsuario"
                                    label="GÉNERO"
                                    variant="outlined"
                                    select
                                    error={!!errors.genero}
                                    helperText={errors.genero || ''}
                                    onChange={handleSeleccionadoSexo}
                                    value={state.ciudadano.sexo}
                                >
                                    <MenuItem value={"MASCULINO"}>MASCULINO</MenuItem>
                                    <MenuItem value={"FEMENINO"}>FEMENINO</MenuItem>
                                    <MenuItem value={"LGTBIQA+"}>LGTBIQA+</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-INE"
                                    label="INE"
                                    variant="outlined"
                                    error={!!errors.ine}
                                    helperText={errors.ine || ''}
                                    onChange={handleSeleccionadoIne}
                                    value={state.ciudadano.ine}
                                    inputProps={{ maxLength: 13 }}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Cargo"
                                    label="CARGO"
                                    variant="outlined"
                                    select
                                    error={!!errors.cargo}
                                    helperText={errors.cargo || ''}
                                    onChange={handleSeleccionadoCargo}
                                    value={state.ciudadano.cat_tipo_cargo_id}
                                >
                                    {cargosState.data && cargosState.data.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.tipo_cargo}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={cargosState.data || []}
                                    getOptionLabel={(option) => option.tipo_cargo}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={cargosState.data.find(option => option.id === state.ciudadano.cat_tipo_cargo_id) || null}
                                    onChange={(event, newValue) => handleSeleccionadoCargo(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="CARGO"
                                            error={!!errors.cargo}
                                            helperText={errors.cargo || ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-telefono"
                                    label="TELÉFONO"
                                    variant="outlined"
                                    error={!!errors.telefono}
                                    helperText={errors.telefono || ''}
                                    onChange={handleSeleccionadoTelefono}
                                    value={state.ciudadano.telefono !== null ? state.ciudadano.telefono : ""}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-correo"
                                    label="CORREO"
                                    variant="outlined"
                                    value={state.ciudadano.email !== null ? state.ciudadano.email : ""}
                                    error={errors.email}
                                    helperText={errors.email || ''}
                                    onChange={handleSeleccionadoEmail}
                                    onKeyPress={(event) => {
                                        maxLengthLimitInput(event, 55, 'email');
                                    }}
                                />
                            </Grid>
                            <hr />
                            <hr />
                            <h5 className='ms-4 mt-3'>DIRECCIÓN</h5>
                            <hr />
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-calle"
                                    label="CALLE"
                                    variant="outlined"
                                    error={errors.calle}
                                    helperText={errors.calle || ''}
                                    value={state.ciudadano.calle}
                                    onChange={handleSeleccionadoCalle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-numero"
                                    label="NUMERO"
                                    variant="outlined"
                                    error={errors.numero}
                                    helperText={errors.numero || ''}
                                    value={state.ciudadano.numero_ext}
                                    onChange={handleSeleccionadoNumero}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-colonia"
                                    label="COLONIA"
                                    variant="outlined"
                                    error={errors.colonia}
                                    helperText={errors.colonia || ''}
                                    value={state.ciudadano.colonia}
                                    onChange={handleSeleccionadoColonia}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-cp"
                                    label="C.P."
                                    variant="outlined"
                                    error={errors.cp}
                                    helperText={errors.cp || ''}
                                    value={state.ciudadano.cp !== null ? state.ciudadano.cp : ""}
                                    onChange={handleSeleccionadoCp}
                                    inputProps={{ maxLength: 5 }}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-localidad"
                                    label="ENTIDAD FEDERATIVA"
                                    variant="outlined"
                                    select
                                    error={errors.estado}
                                    helperText={errors.estado || ''}
                                    value={state.ciudadano.estado}
                                    onChange={handleSeleccionadoEstado}
                                >
                                    <MenuItem value={"AGUASCALIENTES"}>AGUASCALIENTES</MenuItem>
                                    <MenuItem value={"BAJA CALIFORNIA"}>BAJA CALIFORNIA</MenuItem>
                                    <MenuItem value={"BAJA CALIFORNIA SUR"}>BAJA CALIFORNIA SUR</MenuItem>
                                    <MenuItem value={"CAMPECHE"}>CAMPECHE</MenuItem>
                                    <MenuItem value={"CHIAPAS"}>CHIAPAS</MenuItem>
                                    <MenuItem value={"CHIHUAHUA"}>CHIHUAHUA</MenuItem>
                                    <MenuItem value={"CIUDAD DE MÉXICO"}>CIUDAD DE MÉXICO</MenuItem>
                                    <MenuItem value={"COAHUILA DE ZARAGOZA"}>COAHUILA DE ZARAGOZA</MenuItem>
                                    <MenuItem value={"COLIMA"}>COLIMA</MenuItem>
                                    <MenuItem value={"DURANGO"}>DURANGO</MenuItem>
                                    <MenuItem value={"GUANAJUATO"}>GUANAJUATO</MenuItem>
                                    <MenuItem value={"GUERRERO"}>GUERRERO</MenuItem>
                                    <MenuItem value={"HIDALGO"}>HIDALGO</MenuItem>
                                    <MenuItem value={"JALISCO"}>JALISCO</MenuItem>
                                    <MenuItem value={"ESTADO DE MÉXICO"}>ESTADO DE MÉXICO</MenuItem>
                                    <MenuItem value={"MICHOACÁN"}>MICHOACÁN</MenuItem>
                                    <MenuItem value={"MORELOS"}>MORELOS</MenuItem>
                                    <MenuItem value={"NAYARIT"}>NAYARIT</MenuItem>
                                    <MenuItem value={"NUEVO LEÓN"}>NUEVO LEÓN</MenuItem>
                                    <MenuItem value={"OAXACA"}>OAXACA</MenuItem>
                                    <MenuItem value={"PUEBLA"}>PUEBLA</MenuItem>
                                    <MenuItem value={"QUERÉTARO"}>QUERÉTARO</MenuItem>
                                    <MenuItem value={"QUINTANA ROO"}>QUINTANA ROO</MenuItem>
                                    <MenuItem value={"SAN LUIS POTOSÍ"}>SAN LUIS POTOSÍ</MenuItem>
                                    <MenuItem value={"SINALOA"}>SINALOA</MenuItem>
                                    <MenuItem value={"SONORA"}>SONORA</MenuItem>
                                    <MenuItem value={"TABASCO"}>TABASCO</MenuItem>
                                    <MenuItem value={"TAMAULIPAS"}>TAMAULIPAS</MenuItem>
                                    <MenuItem value={"TLAXCALA"}>TLAXCALA</MenuItem>
                                    <MenuItem value={"VERACRUZ"}>VERACRUZ</MenuItem>
                                    <MenuItem value={"YUCATÁN"}>YUCATÁN</MenuItem>
                                    <MenuItem value={"ZACATECAS"}>ZACATECAS</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="MUNICIPIO"
                                    variant="outlined"
                                    select
                                    error={!!errors.municipio}
                                    helperText={errors.municipio || ''}
                                    onChange={handleSeleccionadoMunicipio}
                                    value={state.ciudadano.municipio_id}
                                >
                                    {state.municipios && state.municipios.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.municipio}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.localidad}
                                    helperText={errors.localidad || ''}
                                    onChange={handleSeleccionadoLocalidad}
                                    value={state.ciudadano.localidad_id}
                                >
                                    {state.localidades.length > 0 ?
                                        state.localidades.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.localidad}
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={null}>
                                            SIN LOCALIDADES
                                        </MenuItem>
                                    }
                                </TextField>
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarCiudadano();
                                }}>
                                    EDITAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para crear */}

                <Modal
                    open={openModal}
                    onClose={() => setopenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            CREAR SOLICITUD
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.citizens || []}
                                    getOptionLabel={(option) => option.nombre+" "+option.apellido_paterno+" "+option.apellido_paterno}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{ borderRadius: "30px" }}
                                    value={state.citizens.find(option => option.id === formularioState.ciudadano_id) || null}
                                    onChange={(event, newValue) => handleCitizen(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="CIUDADANO"
                                            error={!!errors.citizen}
                                            helperText={errors.citizen || ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="FUENTES"
                                    variant="outlined"
                                    select
                                    error={!!errors.font}
                                    helperText={errors.font || ''}
                                    onChange={handleChangeFont}
                                    value={formularioState.fuente_solicitud_id}
                                >
                                    {state.fonts.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.fuente_solicitud}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            {formularioState.fuente_solicitud_id === 1 ?
                                <Grid item xs={12}>
                                    <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            label="FECHA DE AUDIENCIA"
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ width: 1 }}
                                            // slotProps={{ textField: { helperText: 'Please fill this field' } }}
                                            // defaultValue={today}
                                            format="DD/MM/YYYY"
                                            views={['year', 'month', 'day']}
                                            onChange={handleChangeDate}
                                            // disablePast
                                        />
                                    </LocalizationProvider>
                                </Grid> : <></>
                            }
                            {
                                formularioState.fuente_solicitud_id === 3 ?
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="N° GIRA"
                                            error={!!errors.numTour}
                                            helperText={errors.numTour || ''}
                                            value={formularioState.numero_gira}
                                            onChange={({ target }) => setFormulario({ ...formularioState, numero_gira: target.value })}
                                            onKeyPress={onlyNumber}
                                        />
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                formularioState.fuente_solicitud_id === 3 ?
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="LUGAR DE GIRA"
                                            value={formularioState.lugar_gira}
                                            error={!!errors.nameTour}
                                            helperText={errors.nameTour || ''}
                                            onChange={({ target }) => setFormulario({ ...formularioState, lugar_gira: target.value })}
                                        />
                                    </Grid>
                                    :
                                    <></>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label="OBSERVACIONES"
                                    value={formularioState.observaciones}
                                    error={!!errors.observations}
                                    helperText={errors.observations || ''}
                                    onChange={handleChangeObservations}
                                />
                            </Grid>



                            <Grid marginLeft="auto" item >
                                <ColorButton
                                    onClick={createRequest}
                                >
                                    Crear
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar Solicitud */}

                <Modal
                    open={state.openEditar}
                    onClose={() => handleClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            EDITAR SOLICITUD
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="FUENTES"
                                    variant="outlined"
                                    select
                                    error={!!errors.font}
                                    helperText={errors.font || ''}
                                    onChange={handleEditFont}
                                    value={state.citizenEditSelected.fuente_solicitud_id}
                                >
                                    {state.fonts.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.fuente_solicitud}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            {state.citizenEditSelected.fuente_solicitud_id === 1 ?
                                <Grid item xs={12}>
                                    <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            label="FECHA DE AUDIENCIA"
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ width: 1 }}
                                            // slotProps={{ textField: { helperText: 'Please fill this field' } }}
                                            // defaultValue={today}
                                            format="DD/MM/YYYY"
                                            views={['year', 'month', 'day']}
                                            onChange={handleEditDate}
                                            value={state.citizenEditSelected.fecha_audiencia ? dayjs(state.citizenEditSelected.fecha_audiencia) : null}
                                        // disablePast
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                :
                                <></>
                            }
                            {state.citizenEditSelected.fuente_solicitud_id === 3 ?
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label="N° de Gira"
                                        value={state.citizenEditSelected.numero_gira}
                                        error={!!errors.numero_gira}
                                        helperText={errors.numero_gira || ''}
                                        onChange={({ target }) => setState({ ...state, citizenEditSelected: { ...state.citizenEditSelected, numero_gira: target.value } })}
                                        onKeyPress={onlyNumber}
                                        disabled={state.citizenEditSelected.fuente_solicitud_id === 3 ? false : true}
                                    />
                                </Grid>
                                :
                                <></>
                            }
                            {state.citizenEditSelected.fuente_solicitud_id === 3 ?
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label="LUGAR DE GIRA"
                                        value={state.citizenEditSelected.lugar_gira}
                                        error={!!errors.lugar_gira}
                                        helperText={errors.lugar_gira || ''}
                                        onChange={({ target }) => setState({ ...state, citizenEditSelected: { ...state.citizenEditSelected, lugar_gira: target.value } })}
                                        disabled={state.citizenEditSelected.fuente_solicitud_id === 3 ? false : true}
                                    />
                                </Grid>
                                :
                                <></>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label="OBSERVACIONES"
                                    value={state.citizenEditSelected.observaciones}
                                    error={!!errors.observations}
                                    helperText={errors.observations || ''}
                                    onChange={handleEditObservations}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton
                                    onClick={editRequest}
                                >
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}

                <Modal
                    open={state.openEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            ¿ESTÁ SEGURO DE ELIMINAR SOLICITUD CON FOLIO?
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <h4>{state.citizenEditSelected.folio}</h4>
                            </Grid>
                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    deleteRequest();
                                }}

                                >
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>

                {/* Modal para crear peticion */}

                <Modal
                    open={state.openPeticion}
                    onClose={() => handleClosePeticion()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            CREAR PETICIÓN
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.tiposPeticion || []}
                                    getOptionLabel={(option) => option.tipo_peticion}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.tiposPeticion.find(option => option.id === formularioPeticionState.tipo_peticion_id) || null}
                                    onChange={(event, newValue) => handleTipoPeticiones(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="TIPO DE PETICIÓN"
                                            error={!!errors.tipo}
                                            helperText={errors.tipo || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="PETICIÓN"
                                    error={!!errors.peticion}
                                    rows={4}
                                    helperText={errors.peticion || ''}
                                    // value={formularioPeticionState.peticion}
                                    onChange={({ target }) => setFormularioPeticion({ ...formularioPeticionState, peticion: target.value })}

                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.dependencias || []}
                                    getOptionLabel={(option) => option.dependencia}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.dependencias.find(option => option.id === formularioPeticionState.dependencia_id) || null}
                                    onChange={(event, newValue) => handleDependencias(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="DEPENDENCIAS"
                                            error={!!errors.dependencia}
                                            helperText={errors.dependencia || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="PRIORIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.atencion}
                                    helperText={errors.atencion || ''}
                                    onChange={handlePrioridad}
                                    value={formularioPeticionState.atencion_inmediata}
                                >
                                    <MenuItem value={true}>
                                        INMEDIATA
                                    </MenuItem>
                                    <MenuItem value={false}>
                                        ALTA
                                    </MenuItem>
                                </TextField>
                            </Grid>




                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearPeticion();
                                }}
                                >
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </div>
        </>

    )
}





export default SolicitudesUser;
